<template>
  <div
    class="wish-pro-box"
    v-if="item"
    v-match-heights="{ el: ['.wish-pro-price'] }"
  >
    <b-modal
      ref="product-delete-wish"
      id="product-delete-wish"
      class="product-modal-inner"
      content-class="defaul-modal"
      hide-footer
      centered
      size="lg"
    >
      <template #modal-title class="bold"
        >{{ $t("remove_product_wishlist") }}
      </template>
      <div class="modal-text d-block border-bottom">
        <p>
          {{ $t("sure_remove_product_wishlist") }}
        </p>
        <!-- <p>Wil je deze producten bewaren voor later maak een account aan</p> -->
      </div>
      <div class="modal-btn-box pt-20">
        <b-button class="btn-success" block @click="clickOk">Ok</b-button>
        <b-button class="btn-success btn-outline" block @click="clickCancel">{{
          $t("cancel")
        }}</b-button>
      </div>
    </b-modal>
    <div class="wish-pro-card">
      <b-link class="delete-icon-card">
        <font-awesome-icon icon="trash-alt" @click="showPopup" />
      </b-link>
      <div class="wish-pro-img">
        <router-link
          :to="`/${item.product.url_key}/`"
          @click.native="$root.$emit('closeSearchModal')"
        >
          <b-img :alt="item.product.name" :src="item.product.small_image.url" />
        </router-link>
      </div>
      <div
        class="wish-pro-offer"
        :class="{
          'opacity-0':
            item.product.price_range.minimum_price.discount.percent_off == 0,
        }"
      >
        -&nbsp;{{
          item.product.price_range.minimum_price.discount.percent_off.toFixed(
            0
          )
        }}%
      </div>
      <div class="wish-pro-name">{{ item.product.name }} wpcc</div>
      <div class="wish-pro-info mt-2">
        <span
          v-if="
            item.product.price_range.minimum_price.regular_price.value !=
            item.product.price_range.minimum_price.final_price.value
          "
          class="adv-price"
          >{{ $t("Adv_price") }}</span
        >
        <br />
        <div class="wish-pro-price">
          <span
            class="old mr-1"
            v-if="
              item.product.price_range.minimum_price.regular_price.value !=
              item.product.price_range.minimum_price.final_price.value
            "
            ><span>{{
              formatCurrency(
                item.product.price_range.minimum_price.regular_price.value
              )
            }}</span></span
          >
          <span class="new"
            ><strong>{{
              formatCurrency(
                item.product.price_range.minimum_price.final_price.value
              )
            }}</strong
            ><small>
              <!-- <span
                v-html="
                  item.product.is_flooring_product == 9057
                    ? 'Per m<sup>2</sup>'
                    : 'Per Stuk'
                "
              ></span> -->
              <span v-if="item.product.unit">
                {{ item.product.unit }}
              </span>
            </small>
          </span>
        </div>
        <DeliveryTime
          :data="item.product.DeliveryTime"
          v-if="item.product.stock_status !== 'IN_STOCK'"
        />
        <p class="wish-pro-stuck-info stuck-size bold pb-15" v-else>
          <span
            v-if="
              item.product.stock_qty > item.product.stock_display_limit ||
              item.product.stock_display_limit == null
            "
          >
            {{ $t("in_stock")
            }}<!--//To be determined by HomingXl how to implement : {{ product.stock_qty }} {{ $t("pieces") }} -->
          </span>
          <span v-else>
            {{ $t("x_left_in_stock", { qty: item.product.stock_qty })
            }}<!--//To be determined by HomingXl how to implement : {{ product.stock_qty }} {{ $t("pieces") }} -->
          </span>
        </p>
      </div>
    </div>
    <div class="wish-pro-btn-wrap">
      <div class="wish-pro-cart">
        <button class="btn btn-success" @click="addToCart()">
          <span class="icon-cart"><i class="fa fa-plus"></i></span>
          <font-awesome-icon icon="shopping-cart" class="cart-icon" />
        </button>
      </div>
      <div class="wish-pro-more">
        <b-link :to="`/` + item.product.url_key + '/'" class="btn btn-success"
          >{{ $t("more_info") }}
        </b-link>
      </div>
    </div>
  </div>
</template>
<script>
import DeliveryTime from "@/esf_utrecht_bankxl/core/components/products/product-detail/DeliveryTime";

export default {
  name: "WishlistProductCardComponent",

  props: {
    item: Object,
  },
  components: {
    DeliveryTime,
  },
  data() {
    return {};
  },
  computed: {
    isInWishlist() {
      return this.$store.getters["wishlist/isProductInWishlist"](
        this.item.product.sku
      );
    },
  },
  methods: {
    async addToCart() {
      if (this.item.product.__typename == "SimpleProduct") {
        const item =
          '{data: {sku: "' +
          this.item.product.sku +
          '", quantity:' +
          1 +
          "}}  ";
        let retval = await this.$store.dispatch("cart/addToCart", {
          type: this.item.product.__typename,
          item: item,
        });

        if (retval == false) {
          const msg = {
            type: "danger",
            title: this.$t("shopping_basket"),
            text: this.$t("not_add_product"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        } else {
          // this.$bvModal.show("modal-1");
          this.$root.$emit("openCartPopup");
        }
      } else {
        this.$router.push(`/` + this.item.product.url_key + "/");
      }
    },
    clickOk() {
      this.$bvModal.hide("product-delete-wish");
      this.UpdateIsInWishList();
    },
    clickCancel() {
      this.$bvModal.hide("product-delete-wish");
    },
    showPopup() {
      this.$refs["product-delete-wish"].show();
    },
    UpdateIsInWishList() {
      if (this.isInWishlist) {
        this.$store.dispatch("wishlist/removeProductFromWishlist", {
          sku: this.item.product.sku,
          parentSku: null,
        });
      } else {
        if (this.item.product.__typename == "SimpleProduct") {
          this.$store.dispatch("wishlist/addProductToWishlist", {
            sku: this.item.product.sku,
            parentSku: null,
          });
        } else {
          const childSku = this.$store.getters["product/getCurrentChildSku"];
          if (childSku == null) {
            const msg = {
              type: "danger",
              title: this.$t("wishlist error"),
              text: this.$t("wishlist_select_options"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            //this.$router.push("/" + this.product.url_key+'/');
          } else {
            this.$store.dispatch("user/setProductWishlistStatus", {
              sku: childSku,
              parentSku: this.item.product.sku,
            });
          }
        }
      }
    },
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount, false);
    },
  },
};
</script>

<style lang="scss" scoped>
.adv-price {
  font-size: 13px;
  color: #525252;
  font-weight: 400;
}
</style>
