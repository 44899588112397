<template>
  <div class="wishlist-product-table pb-20">
    <h3 class="product-heading pb-25">{{ $t("compose") }}</h3>
    <table class="product-parameter-table">
        <thead>
            <tr>
                <th></th>
                <th>{{ $t("Article") }}</th>
                <th>{{ $t("no_of") }}</th>
                <th>{{ $t("delivery_time_text")}}</th>
                <th>{{ $t("Price") }}</th>
            </tr>
        </thead>
      
        <tbody>
            <template v-for="item in grouped">
                <template v-if="!(item.required === false && item.options.length === 0)">
                    <!-- Title -->
                    <tr :key="item.option_id">
                        <th class="title-row" :colspan="7">{{ item.title }}</th>
                    </tr>

                    <tr v-for="option in item.options" :key="option.id" @click="handleSelectionChange(item.option_id, option.id)">

                        <!-- Radio -->
                        <td>
                            <b-form-radio 
                                v-model="selectedOptions[item.option_id]" 
                                :value="option.id"
                                :name="'option-group-' + item.option_id"
                                @change="handleSelectionChange(item.option_id, option.id)"
                            ></b-form-radio>
                        </td>

                        <!-- Product Detail -->
                        <td>
                            <div class="first-col">
                                <div class="first-col-img" v-if="showImage">
                                    <b-img
                                        :src="option.product.image.url"
                                        :alt="option.product.url_key"
                                        class="wishlist-prd"
                                    />
                                </div>
                                <div class="first-col-content">
                                    {{ option.product.name }}
                                </div>
                            </div>
                        </td>

                        <!-- Quantity -->
                        <td>
                            <div class="stock-cont-div" v-if="option.can_change_quantity">
                                <div class="number-count-inner">
                                    <span class="number-count">
                                        <input
                                            class="stock-count"
                                            type="number"
                                            v-model.number="option.quantity"
                                            min="1"
                                            max="100"
                                            @change="handleQuantityChange(item.option_id)"
                                        />
                                    </span>
                                    <span class="number-countarrow">
                                        <div class="count-up" @click="quantityUp(option)">
                                            <font-awesome-icon icon="chevron-up" />
                                        </div>
                                        <div class="count-down" @click="quantityDown(option)">
                                            <font-awesome-icon icon="chevron-down" />
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div v-else class="text-right">
                                {{ option.quantity }}
                            </div>
                        </td>

                        <!-- Delivery Time -->
                        <td>
                            <p class="lever-txt text-center text-16" :style="`color: ${option.product.DeliveryTime.color}`">
                                {{ option.product.DeliveryTime.short }}
                            </p>
                        </td>

                        <!-- Price -->
                        <td>
                            {{ getPrice( option.product.price_range.minimum_price ) }}
                        </td>
                    </tr>

                    <!-- If there are no products -->
                    <tr v-if="item.options.length<=0" :key="item.option_id">
                        <td colspan="4">{{ $t('There_are_currently_no_options_available_for_this_component') }}</td>
                    </tr>
                </template>
            </template>
        </tbody>
    </table>

    <!-- Total Price -->
    <div class="total-price">
      <div v-if="!noAvailableOptions">
        <span class="price-text pr-3">{{ $t("total_price") }}</span>
        <span class="final-price-text" v-if="total!=0">{{ formatCurrency(total) }}</span>

        <span v-else>
            <span class="final-price-text">
                {{ formatCurrency(finalPrice) }}
            </span>
        </span>
      </div>
      <div class="total-price-btns">
        <b-button
          :disabled="hasZeroQty || noAvailableOptions"
          variant="success"
          class="big-btn"
          @click="submitProduct"
          ><span class="pr-1 text-16">{{ $t("add_to_cart") }}</span
          ><span><font-awesome-icon icon="chevron-circle-right" /></span
        ></b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Logger } from "@storefront/core/lib/logger";

export default {
    name: "ProductParametersBundle",
    data() {
        return {
            selectedOptions: {},
            total: 0
        };
    },
    
    computed: {
        ...mapGetters({
            product: "product/getCurrentProduct",
            grouped: "product/getCurrentBundle",
        }),
        hasZeroQty() {
            if (this.grouped) {
                return this.grouped.every((group) =>
                    group.options.every((option) => option.quantity === 0)
                );
            }
            return true;
        },
        regularPrice() {
            return this.product
                ? this.product.price_range.minimum_price.regular_price
                    .value
                : this.product &&
                    this.product.price_range.minimum_price.regular_price.value;
        },
        finalPrice() {
            return this.product
                ? this.product.price_range.minimum_price.final_price.value
                : this.product &&
                    this.product.price_range.minimum_price.final_price.value;
        },
        showImage() {
            if (this.product && this.product.layout_features!=null && this.product.layout_features.includes('partimage')) {
                return true;
            } else {
                return false;
            }
        },
        // Check if any group has no options
        noAvailableOptions() {
            return this.grouped.some(group => group.required && group.options.length === 0);
        }
    },

    mounted() {
        this.initializeDefaults();
    },
    
    methods: {
        // Populate selectedOptions based on `option.is_default`
        initializeDefaults() {
            this.grouped.forEach(group => {
                const defaultOption = group.options.find(option => option.is_default);
                if (defaultOption) {
                    this.$set(this.selectedOptions, group.option_id, defaultOption.id);
                }
            });
        },

        // Submit Bundle Product
        async submitProduct() {
            let selectedOptions = [];

            this.grouped.forEach((group) => {
                const selectedOptionId = this.selectedOptions[group.option_id];
                const selectedOption = group.options.find((opt) => opt.id === selectedOptionId);

                if (selectedOption) {
                    selectedOptions.push(`"${selectedOption.uid}"`);
                }
            });
            const payload = `{quantity:1 sku:"${this.product.sku}" selected_options:[${selectedOptions.join(",")}] }`;

            try {
                const retval = await this.$store.dispatch("cart/addProductToCart", { items: payload });

                Logger.debug("submitProduct Payload:", payload);
                Logger.debug("submitProduct Response:", retval);

                this.$root.$emit("openCartPopup");
                this.scrollToTop();
            } catch (error) {
                console.error("Failed to add product to cart:", error);
            }
        },

        // Scroll To Top
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },

        getPrice(minPriceRange) {
            let mainPrice = this.finalPrice || this.regularPrice;
            
            if (minPriceRange?.final_price?.value != null) {
                return this.$helpers.formatCurrency(Math.min(minPriceRange.final_price.value, mainPrice), false);
            }
            else if (minPriceRange?.regular_price?.value != null) { 
                return this.$helpers.formatCurrency(Math.min(minPriceRange.regular_price.value, mainPrice), false);
            }

            return this.$helpers.formatCurrency(mainPrice, false);
        },

        formatCurrency(amount) {
            return this.$helpers.formatCurrency(amount, false);
        },

        quantityUp(option) {
            option.quantity++;
            this.handleQuantityChange(option.option_id);
        },

        quantityDown(option) {
            if (option.quantity > 1) {
                option.quantity--;
                this.handleQuantityChange(option.option_id);
            }
        },

        // Handle the radio change
        handleSelectionChange(optionId, selectedId) {
            this.selectedOptions[optionId] = selectedId;
            this.calculateTotal();
        },

        // Handle the quantity change
        handleQuantityChange() {
            this.calculateTotal();
        },

        // Loop through selected options to calculate total
        calculateTotal() {
            this.total = 0;

            this.grouped.forEach((group) => {
                const selectedOptionId = this.selectedOptions[group.option_id];
                if (selectedOptionId) {
                    const selectedOption = group.options.find(
                        (opt) => opt.id === selectedOptionId
                    );
                    if (selectedOption) {
                        this.total += selectedOption.product.price_range.minimum_price.final_price.value;
                    }
                } else {
                    const lowestPrice = Math.min(
                        ...group.options.map(
                            (opt) => opt.product.price_range.minimum_price.final_price.value
                        )
                    );
                    this.total += lowestPrice;
                }
            });
        }

    },
};
</script>
